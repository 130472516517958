































































































































































import { Component, Vue } from 'vue-property-decorator';
import SchedulePaymentDateWealthViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/link-goal/schedule-payment-date-wealth';

@Component({ name: 'StepSchedulePaymentDateWealth' })
export default class StepSchedulePaymentDateWealth extends Vue {
  step_schedule_payment_date_model = Vue.observable(
    new SchedulePaymentDateWealthViewModel(this),
  );

  created() {
    this.step_schedule_payment_date_model.initialize();
  }
}
