import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import Vue from 'vue';

import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import CreateCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/create-custom-investor-goal-command';
import RetirementInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';
import CreateLinkSowosWealthCommand from '@/modules/flagship/investor-goal/link-sowos-wealth/application/commands/create-link-sowos-wealth-command';
import { ActivateRecurringContributionCommand } from '@/modules/my-investment/allianz/allianz-account/application/commands';
import CreateInvestmentFundHireCommand
  from '@/modules/my-investment/allianz/access-more-strategies/application/commands/create-investment-fund-hire-command';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import {
  AllianzAccountReportEntity,
} from '@/modules/my-investment/allianz/allianz-account-report/domain/entities/allianz-account-report-entity';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class SchedulePaymentDateWealthViewModel {
  @Inject(TYPES.CREATE_LINK_SOWOS_WEALTH_COMMAND)
  private readonly create_link_wealth_command!: CreateLinkSowosWealthCommand;

  @Inject(TYPES.ACTIVATE_RECURRING_CONTRIBUTION_COMMAND)
  private readonly activate_recurring_contribution_command!: ActivateRecurringContributionCommand;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.CREATE_CUSTOM_INVESTOR_GOAL_COMMAND)
  private readonly create_custom_investor_goal!: CreateCustomInvestorGoalCommand;

  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND)
  private readonly create_retirement_invest_command!: RetirementInvestorGoalCommand;

  @Inject(TYPES.CREATE_INVESTMENT_FUND_HIRE_COMMAND)
  private readonly create_investment_fund_hire_command!: CreateInvestmentFundHireCommand;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-schedule-payment-date-wealth';

  AVAILABLE_DAYS = [...Array(28).keys()].map((i) => ({ item: `${i18n.t(`${this.i18n_namespace}.select_option`, { option: i + 1 })}`, value: i + 1 }));

  private recurrent_amount = '';

  funds: Array<AllianzAccountReportEntity> = [];

  change_date = true;

  payment_day = 0;

  current_payment_day = 0;

  has_recurring_contributions = true;

  recurrent_assigned_amount = 0;

  skip_steep = true;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  budget_goal_state = this.manager_service.getAccessMoreStrategiesState();

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  }

  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    await this.loadAllianzData();
    this.investor_goal_state.is_loading = false;
  }

  changeDate = () => {
    if (this.change_date) {
      this.payment_day = 1;
    } else {
      this.payment_day = this.current_payment_day;
    }
  }

  createCustomInvestorGoal = async () => {
    const payload = {
      id: this.investor_goal_state.investor_goal.id,
      goal_name: this.investor_goal_state.investor_goal.goal_name,
      desired_amount: this.investor_goal_state.investor_goal.desired_amount,
      fixed_time_adjusted: this.investor_goal_state.investor_goal.fixed_time_adjusted.toFixed(2),
      investor_goal: {
        investor_profile_id: this.investor_goal_state.investor_goal.investor_profile_id,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
        monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
        initial_investment_date: this.investor_goal_state.investor_goal.initial_investment_date,
        final_investment_date: this.investor_goal_state.investor_goal.final_investment_date,
      },
      custom_goal_type: this.investor_goal_state.custom_investor_goal.custom_goal_type,
    };
    if (!this.investor_goal_state.investor_goal.already_created) {
      await this.create_custom_investor_goal.execute(payload);
    }
  }

  createRetirementInvestorGoal = async () => {
    const retirement_investor_goal_entity: RetirementInvestorGoalEntity = {
      id: this.investor_goal_state.investor_goal.id,
      retirement_age_adjusted: this.investor_goal_state.retirement_fund
        .retirement_age_adjusted,
      issued_age: this.investor_goal_state.retirement_fund.issued_age,
      retirement_range_adjusted: (this.investor_goal_state.retirement_fund
        .retirement_range_adjusted * 12).toString(),
      pension_range_adjusted: (this.investor_goal_state
        .retirement_fund.pension_range_adjusted * 12),
      monthly_pension_adjusted: this.investor_goal_state.retirement_fund.calculated_pension
        .toString(),
      investor_goal: {
        investor_profile_id: this.investor_goal_state.investor_goal.investor_profile_id,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        initial_amount_adjusted: this.investor_goal_state.investor_goal.initial_amount_adjusted,
        accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
        monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount
          .toString(),
        initial_investment_date: this.investor_goal_state.investor_goal.initial_investment_date,
        final_investment_date: this.investor_goal_state.investor_goal.final_investment_date,
      },
    };

    await this.create_retirement_invest_command.execute(retirement_investor_goal_entity);
  }

  updatePaymentDate = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      if (this.investor_goal_state.investor_goal_type) {
        if (this.investor_goal_state.investor_goal_type !== 'retirement_fund') {
          await this.createCustomInvestorGoal();
        } else {
          await this.createRetirementInvestorGoal();
        }
        await this.createLinkSowosWealth();
        if (this.change_date
          || this.investor_goal_state.recurring_contribution.amount
          !== this.investor_goal_state.current_recurrent_contribution.toString()) {
          this.investor_goal_state.recurring_contribution.monthly_collection_day = this.payment_day;
          await this.activate_recurring_contribution_command
            .execute(this.investor_goal_state.recurring_contribution);
        }
        await this.reloadAllianzData();
        if (!this.investor_goal_state.investor_goal.already_created) {
          this.investor_goal_state.goal_created_and_linked = true;
        }
      } else {
        await this.createBucket();
      }
      this.view.$emit('endProcess');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_payment_date'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  endProcessWithoutChangeOnRecurringContribution = async () => {
    try {
      if (this.investor_goal_state.investor_goal_type) {
        if (this.investor_goal_state.investor_goal_type !== 'retirement_fund') {
          await this.createCustomInvestorGoal();
        } else {
          await this.createRetirementInvestorGoal();
        }
        await this.createLinkSowosWealth();
      } else {
        await this.createBucket();
      }
      this.view.$emit('endProcess');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_payment_date'));
    }
  }

  loadAllianzData = async () => {
    try {
      const allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id, reload: true });
      this.investor_goal_state.recurring_contribution.id = allianz_account.id;
      if (this.investor_goal_state.define_contribution_later) {
        await this.endProcessWithoutChangeOnRecurringContribution();
      } else {
        this.skip_steep = false;
        this.recurrent_amount = this.investor_goal_state.recurring_contribution.amount;
        this.hasRecurringContributions(
          allianz_account.home_desire,
          allianz_account.monthly_collection_day,
          allianz_account.amount,
        );
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_reload_allianz_data'));
      }
    }
  }

  reloadAllianzData = async () => {
    try {
      const allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id, reload: true });
      const recurring_contributions_updated_event = new CustomEvent(
        'allianz.update.recurring.contribution', { detail: { ...allianz_account } },
      );
      window.dispatchEvent(recurring_contributions_updated_event);
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_reload_allianz_data'));
      }
    }
  }

  hasRecurringContributions = (
    has_recurring_contribution: boolean,
    monthly_collection_day: number | undefined,
    amount: number | undefined,
  ) => {
    if (!has_recurring_contribution) {
      this.has_recurring_contributions = false;
      this.payment_day = 1;
    } else {
      this.payment_day = monthly_collection_day || 1;
      this.current_payment_day = monthly_collection_day || 0;
      this.investor_goal_state.current_recurrent_contribution = amount || 0;
    }
  }

  createLinkSowosWealth = async () => {
    const investor_goals_dto = {
      total_active_goals_balance: this.investor_goal_state.total_active_goals_balance.toString(),
      price_by_unit: this.investor_goal_state.price_by_unit.toFixed(6),
      unassigned_balance: this.investor_goal_state.unassigned_balance.toString(),
      recurrent_unassigned_amount: this.investor_goal_state.recurrent_unassigned_contribution
        .toString(),
      investor_goals: [{
        investor_goal_id: this.investor_goal_state.investor_goal.id,
        active_goal_amount: this.investor_goal_state.total_active_goals_balance
          .toString(),
        recurrent_assigned_amount: this.investor_goal_state.recurrent_assigned_amount.toString(),
      }],
    };
    await this.create_link_wealth_command.execute(investor_goals_dto);
    if (!this.investor_goal_state.investor_goal.already_created) {
      this.investor_goal_state.goal_created_and_linked = true;
    }
    const linked_goals_event = new CustomEvent('wealth.linked.goal', { detail: { ...investor_goals_dto } });
    window.dispatchEvent(linked_goals_event);
  }

  createBucket = async (): Promise<boolean> => {
    try {
      this.budget_goal_state.is_loading = true;
      const initial_amount = parseCurrencyToNumber(
        this.budget_goal_state.initial_balance,
      );
      const goal_contribution = this.investor_goal_state.recurrent_assigned_amount;
      const recurring_contribution_amount = parseFloat(
        this.investor_goal_state.recurring_contribution.amount,
      );
      const recurring_unassigned_amount = this.investor_goal_state
        .recurrent_unassigned_contribution;
      const access_more_strategies_dto = {
        investor_goal_id: this.budget_goal_state.investor_goal.id,
        initial_investment_date: this.datetimeValue.create().toString(),
        investment_product_fund_type_id: this.budget_goal_state.strategy.id,
        initial_amount,
        goal_contribution,
        update_home_desired: !this.investor_goal_state.define_contribution_later,
        recurring_contribution_amount,
        monthly_collection_day: this.payment_day,
        recurring_unassigned_amount,
        term: this.budget_goal_state.investor_goal.term.toFixed(2),
        goal_name: this.budget_goal_state.strategy.label,
        was_accepted: this.budget_goal_state.customer_agreement.was_accepted,
        agreement_type_id: this.budget_goal_state.customer_agreement.agreement_type_id || null,
        accepted_on: this.budget_goal_state.customer_agreement.accepted_on || null,
      };
      await this.create_investment_fund_hire_command.execute(access_more_strategies_dto);
      this.budget_goal_state.successfully_created = true;
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.complete_flow'));
      return false;
    } finally {
      this.budget_goal_state.is_loading = false;
    }
  }

  goToPrevStep = () => {
    this.view.$emit('prevStep');
  }
}
